@import 'core/variables';
@import 'typography/mixins';
@import 'theming/functions';

$text-color: app-color(text-main-color);

.app-layout {
  display: flex;
  flex-direction: column;
  position: relative;

  &__navbar {
    display: flex;
    align-items: center;
    width: 100%;
    height: $navbar-height;
    max-height: $navbar-height;
    z-index: 3;

    overflow: hidden;

    position: fixed;
    top: 0px;
  }

  &__header-background {
    position: fixed;
    top: $navbar-height;
    width: 100%;
    height: $header-height;
    pointer-events: none;
    z-index: 2;
  }

  &__page {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: $navbar-height;
    min-height: calc(100vh - $navbar-height);

    &--full-screen {
      width: 100%;
      padding: 0 15px;
    }
  }

  &__footer {
    position: relative;
    width: 100%;
    padding-right: $page-padding-size;
    padding-left: $page-padding-size;
  }
}
