$page-padding-size: 15px;

$input-border-radius: 4px;
$input-extra-small-height: 24px;
$input-small-height: 32px;
$input-height: 42px;
$input-big-height: 50px;
$input-margin-bottom: 20px;
$input-textarea-min-height: 80px;

$navbar-height: 60px;
$header-height: 50px;
$nav-height: 32px;
$nav-items-margin-left: 86px;
$tabs-radius: 6px;
$common-gap: 10px;
$scrollbar-size: 5px;

$table-expander-size: 20px;
$table-header-height: 30px;
$table-row-height: 24px;
$table-panel-height: 36px;
$table-panel-big-height: 40px;
$table-padding-size: 6px;

$progress-bar-height: 2px;

$slider-handle-width: 38px;
$slider-handle-small-width: 24px;
$slider-handle-height: 14px;
